.mbg {
    --bs-bg-opacity: 1;
    background-color: #ffffff;
}

.mbgb {
    --bs-bg-opacity: 1;
    background-color: #F3F3F3;
}

.afterNavBar {
    padding-top: 100px;
}

.mlink a {
    color: #305170;
    text-decoration: none;
    /* padding-left: calc(1em + 0.8vw); */
}

.mlink a:hover {
    color: #305170;
    text-decoration: underline;
}

.mline {
    color: #000000;
    width: 90%;
    margin: 0 auto;
    margin-top: 5em;
}
.mlinet {
    color: #000000;
    margin: 0 auto;
  
}
.mlineb {
    color: #000000;
    margin: 0 auto;
    margin-top: 5em;
}


.mlink {
    font-size: 20px;
    --bs-btn-bg: #305170;
}

.mtext h2 {
    font-size: calc(1em + 0.3vw);
    font-family: "Montserrat", Sans-serif;
}

.row {
    align-items: center;
    font-family: "Montserrat", Sans-serif;
}

.mh5 {
    line-height: 1.7em;
    text-align: justify;
}

p {
    font-size: 1.0em;
    
}
ul {
    font-size: 1.1em;
}